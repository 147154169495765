import { createContext, useState } from 'react';
import * as THREE from 'three';

export const RoomContext = createContext();
export const RoomProvider = ({ children }) => {
  const [selectedRoom, setSelectedRoom] = useState('first');
  const [selectedProduct, setSelectedProduct] = useState('closed');
  const [initialCamera, setInitialCamera] = useState(new THREE.Vector3(0.06636178394072342, 2.2487226924375983, 9.4));
  const [initialTarget, setInitialTarget] = useState(new THREE.Vector3(0, 2, 0));
  const [animationCompleted, setAnimationCompleted] = useState(false);
  const [showView, setShowView] = useState(false);

  return (
    <RoomContext.Provider value={{showView, setShowView, selectedRoom, setSelectedRoom, selectedProduct, setSelectedProduct,initialCamera, setInitialCamera ,initialTarget, setInitialTarget,animationCompleted, setAnimationCompleted}}>
      {children}
    </RoomContext.Provider>
  );
};
