import React, { useState } from 'react';

// @ts-ignore
function ShoppingCart({ cartItems }) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <span
                onClick={() => setIsOpen(!isOpen)}
            >
                Cart
                {cartItems.length > 0 && (
                    <span className="absolute top-0 right-0 bg-red-600 rounded-full text-white px-2 py-1 text-xs">
            {cartItems.length}
          </span>
                )}
            </span>


                <div className={`fixed top-0 right-0 h-screen w-1/3 bg-white shadow-lg overflow-y-auto transform 
                transition-transform ease-in-out duration-300 ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}>
                    <button
                        onClick={() => setIsOpen(false)}
                        className="absolute top-4 right-4 p-2 bg-red-500 hover:bg-red-600 text-white rounded-full"
                    >
                        X
                    </button>
                    <h2 className="text-xl font-bold p-4 border-b">Your Cart</h2>
                    {cartItems.length === 0 ? (
                        <p className="p-4">Your cart is empty.</p>
                    ) : (
                        <ul>
                            {cartItems.map(
                                //@ts-ignore
                                item => (
                                <li key={item.id} className="flex justify-between p-4 border-b">
                                    <span>{item.name}</span>
                                    <span>${item.price.toFixed(2)}</span>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
        </>
    );
}

export default ShoppingCart;
