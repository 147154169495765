import React, {useState} from "react";
import {SignOutButton} from "@clerk/clerk-react";
import {
    AiOutlineDashboard,
    AiOutlineShoppingCart,
    AiOutlineUser,
    AiOutlineTransaction,
    AiOutlineTag,
    // AiOutlineLogout,
    AiOutlineInstagram
} from 'react-icons/ai';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  // CartesianGrid,
  Tooltip,
  // Legend,
  ResponsiveContainer
} from 'recharts';

function AdminDashboard() {
    const mockSalesAndCostsData = [
        { day: 'Mon', sales: 240000, costs: 120000},
        { day: 'Tue', sales: 221000, costs: 100000 },
        { day: 'Wed', sales: 229000, costs: 110000 },
        { day: 'Thu', sales: 200000, costs: 90000 },
        { day: 'Fri', sales: 218000, costs: 100000 },
        { day: 'Sat', sales: 250000, costs: 125000 },
        { day: 'Sun', sales: 248000, costs: 100000 }
    ];

    const mockSessionsData = [
        { day: 'Mon', sessions: 16000 },
        { day: 'Tue', sessions: 12000 },
        { day: 'Wed', sessions: 14000 },
        { day: 'Thu', sessions: 11000 },
        { day: 'Fri', sessions: 15000 },
        { day: 'Sat', sessions: 18000 },
        { day: 'Sun', sessions: 10000},
    ];

    const mockOrdersData = [
        { id: '#6548', customer: 'Joseph Wheeler', status: 'Pending', total: '$999.29' },
        { id: '#6547', customer: 'Joseph Wheeler', status: 'Completed', total: '$72.40' },
    ]

    return (
        <div className={"flex"} style={{ backgroundImage: "url(/images/admin_dashboard_background.png)", backgroundSize: "cover"}}>
            <Sidebar />
            <div className={"flex-1 flex flex-col"}>
                {/* Wrap the cards in a flex container */}
                <div className="flex w-full">
                    <TotalSalesAndCostsCard title={"Total Sales And Costs"}>
                        <TotalSalesAndCostsTrendLine data={mockSalesAndCostsData} />
                    </TotalSalesAndCostsCard>
                    <BaseCard title={"Sessions"}>
                        <BaseTrendLine data={mockSessionsData} dataKey={"sessions"} />
                    </BaseCard>
                </div>
                <div className={"flex w-full"}>
                    <BaseCard title={"Total Orders"}>
                        <BaseTrendLine data={mockSessionsData} dataKey={"sessions"} />
                    </BaseCard>
                    <BaseCard title={"Total Profit"}>
                        <BaseTrendLine data={mockSessionsData} dataKey={"sessions"} />
                    </BaseCard>
                    <BaseCard title={"Discounted Amount"}>
                        <BaseTrendLine data={mockSessionsData} dataKey={"sessions"} />
                    </BaseCard>
                </div>
                <div className={"flex w-full"}>
                    <ReportsCard />
                    <TodaysOrdersCard />
                </div>
                <div>
                    <RecentOrdersCard orders={mockOrdersData} />
                </div>
                {/* Add more components or divs here if you have other rows */}
            </div>
        </div>
    )
}

function TodaysOrdersCard() {
    const data = [
        { time: '12am', orders: 3000 },
        { time: '8am', orders: 6000 },
        { time: '4pm', orders: 2000 },
        { time: '11pm', orders: 4000 },
    ];

    return (
        <div className="bg-[#4F95FF80] shadow-lg rounded-lg p-5 m-2 w-[25%]">
            <div className="flex justify-between items-center">
                <h2 className="text-white font-semibold">Today Orders</h2>
            </div>
            <div className="flex flex-col items-center my-4">
                <span className="text-3xl text-white font-bold">16.5K</span>
                <span className="text-green-200 text-sm flex items-center">
          {/* Replace with SVG or icon for arrow */}
                    <span className="font-bold">↑ 6% </span> vs last day
        </span>
            </div>
            <h3 className="text-white text-sm mb-4">Orders Over Time</h3>
            <ResponsiveContainer width="100%" height={200}>
                <LineChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                    <XAxis dataKey="time" stroke="#ffffff" />
                    <YAxis hide={true} />
                    <Tooltip />
                    <Line type="monotone" dataKey="orders" stroke="#82ca9d" dot={false} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}

function ReportsCard() {
    const [activeTab, setActiveTab] = useState('Customers');

    const mockChartData = [
        { day: 'Mon', value: 20000 },
        { day: 'Tue', value: 30000 },
        { day: 'Wed', value: 25000 },
        { day: 'Thu', value: 40000 },
        { day: 'Fri', value: 35000 },
        { day: 'Sat', value: 30000 },
        { day: 'Sun', value: 20000 },
    ];

    // Function to render the LineChart based on activeTab
    const renderChart = () => {
        return (
            <ResponsiveContainer width="100%" height={200}>
                <LineChart data={mockChartData}>
                    <XAxis dataKey="day"
                           stroke="#ffffff" // Change to your desired color
                           axisLine={{ stroke: '#ffffff' }} // Color of the axis line
                           tickLine={{ stroke: 'none' }} // Color of the tick line
                           tick={{ fill: '#ffffff' }} // Color of the tick text
                           padding={{left: 20}}
                    />
                    <YAxis
                        stroke="#ffffff" // Change to your desired color
                        axisLine={{ stroke: 'none' }}
                        tickLine={{ stroke: 'none' }}
                        tick={{ fill: '#ffffff' }} // Color of the tick text
                        padding={{bottom: 20}}

                    />
                    <Tooltip />
                    <Line type="monotone" dataKey="value" stroke="#8884d8" activeDot={{ r: 8 }} />
                </LineChart>
            </ResponsiveContainer>
        );
    };

    return (
        <div className="bg-[#4F95FF80] shadow-lg rounded-lg p-5 m-2 flex flex-col w-[75%]">
            <div className="flex-col">
                <h2 className="text-white font-semibold">Reports</h2>
                <h5 className={"text-white font-semibold"}>Last 7 Days</h5>
                {/* Add more header content or actions here */}
            </div>
            <div className="flex justify-between my-1.5">
                {['Customers', 'Total Products', 'Stock Products', 'Out of Stock', 'Revenue'].map((tab) => (
                    <div
                        key={tab}
                        className={`mb-5 text-white cursor-pointer ${activeTab === tab ? 'font-bold' : 'font-medium'}`}
                        onClick={() => setActiveTab(tab)}
                    >
                        {/* Placeholder for tab values, replace with real data */}
                        <div className="text-lg">{tab === 'Customers' ? '24k' : '3.5k'}</div>
                        <div>{tab}</div>
                        <div className={`w-full h-1 mt-1 ${activeTab === tab ? 'bg-blue-500' : 'bg-white'}`}></div>
                    </div>
                ))}
            </div>
            {renderChart()}
        </div>
    );
}

function TotalSalesAndCostsCard({ title, children }) {
    return (
        <div className="bg-[#4F95FF80] shadow-lg rounded-lg p-5 m-2 flex flex-row justify-between w-[60%]">
            <div className={"flex-col"}>
                <div>
                    <h2 className="text-white font-semibold">{title}</h2>
                    <h5 className={"text-white font-semibold"}>Last 7 days</h5>
                </div>
                <div className="flex items-baseline text-white mb-2">
                    <h1 className="text-3xl font-bold mr-2">$350K</h1>
                    <h3 className="text-xl text-[#fecaca]">$235K</h3>
                </div>
                <div className="flex items-center text-green-200 text-sm mb-6">
                    {/* Insert SVG arrow icon here */}
                    <span>8.56K vs last 7 days</span>
                </div>
            </div>
            <div className="flex-grow ml-20 flex-col">
                <div className={"mb-10"}>
                    <span className="text-sm text-green-200 font-semibold mr-2">Sales</span>
                    <span className="text-sm text-red-200 font-semibold">Cost</span>
                </div>
                {children}
            </div>
        </div>
    );
}

function TotalSalesAndCostsTrendLine({ data }) {
    return (
        <ResponsiveContainer width="100%" height="75%">
            <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                <XAxis dataKey="day"
                       stroke="#ffffff" // Change to your desired color
                       axisLine={{ stroke: '#ffffff' }} // Color of the axis line
                       tickLine={{ stroke: '#ffffff' }} // Color of the tick line
                       tick={{ fill: '#ffffff' }} // Color of the tick text

                />
                <YAxis
                    stroke="#ffffff" // Change to your desired color
                    axisLine={{ stroke: '#ffffff' }} // Color of the axis line
                    tickLine={{ stroke: '#ffffff' }} // Color of the tick line
                    tick={{ fill: '#ffffff' }} // Color of the tick text
                    hide={true} // If you want to hide the Y axis, set this to true
                />
                <Tooltip
                />
                <Line type="monotone" dataKey="sales" stroke="#bbf7d0" activeDot={{ r: 8 }} />
                <Line type="monotone" dataKey="costs" stroke="#fecaca" activeDot={{ r: 8 }} />
            </LineChart>
        </ResponsiveContainer>
    );
}

function BaseTrendLine({ data, dataKey }) {
    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart data={data}>
                <XAxis dataKey="day"
                       hide={true}
                />
                <Tooltip />
                <Line type="monotone" dataKey={dataKey} stroke="#fecaca" activeDot={{ r: 8 }} />
            </LineChart>
        </ResponsiveContainer>
    );
}

function BaseCard({ title, children }) {
    return (
        <div className="bg-[#4F95FF80] shadow-lg rounded-lg p-5 m-2 flex flex-row justify-between w-[40%]">
            <div className={"flex-col"}>
                <div>
                    <h2 className="text-white font-semibold">{title}</h2>
                    <h5 className={"text-white font-semibold"}>Last 7 days</h5>
                </div>
                <div className="flex items-baseline text-white mb-2">
                    <h1 className="text-3xl font-bold mr-2">16.5K</h1>
                </div>
                <div className="flex items-center text-green-200 text-sm mb-6">
                    {/* Insert SVG arrow icon here */}
                    <span>8.56K vs last 7 days</span>
                </div>
            </div>
            <div className="ml-10 w-full flex-grow">
                {children}
            </div>
        </div>
    );
}

function RecentOrdersCard({orders}) {
    return (
        <div className="bg-[#4F95FF80] shadow-lg rounded-lg p-5 m-2 overflow-hidden">
            <h2 className="text-white font-semibold mb-4">Recent Orders</h2>
            <div className="overflow-x-auto">
                <table className="min-w-full">
                    <thead className={"bg-blue-700"}>
                    <tr>
                        <th className="text-left text-white text-sm font-medium px-6 py-3">ID</th>
                        <th className="text-left text-white text-sm font-medium px-6 py-3">CUSTOMER</th>
                        <th className="text-left text-white text-sm font-medium px-6 py-3">STATUS</th>
                        <th className="text-left text-white text-sm font-medium px-6 py-3">TOTAL</th>
                    </tr>
                    </thead>
                    <tbody>
                    {orders.map((order, index) => (
                        <tr key={index}>
                            <td className="px-6 py-4 text-white text-sm font-medium">{order.id}</td>
                            <td className="px-6 py-4 text-white text-sm">{order.customer}</td>
                            <td className={`px-6 py-4 text-sm ${order.status === 'Pending' ? 'text-yellow-300' : 'text-green-300'}`}>{order.status}</td>
                            <td className="px-6 py-4 text-white text-sm">{order.total}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

function Sidebar() {
    const handleInstagramConnect = () => {
        console.log("Connect instagram clicked");

        // Your Instagram App's Client ID
        const clientId = '1119399445768106';
        // Redirect URI registered in your Instagram app settings
        const redirectUri = 'https://ee52-70-170-118-6.ngrok-free.app/admin';
        // The scope of access
        const scope = 'user_profile,user_media';
        // Constructing the Instagram OAuth URL
        const instagramAuthUrl = `https://api.instagram.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=code`;

        // Redirecting the user to Instagram's OAuth authorization page
        window.location.href = instagramAuthUrl;
    }

    return (
        <div className="bg-[#303B3C66] h-screen p-5 text-white">
            <div className="flex flex-col">
                {/* Logo or Brand Name */}
                <div className="text-3xl mb-10">GDFz</div>
                <div className={"text-xs mb-5 font-bold"}>MAIN MENU</div>
                {/* Navigation Items */}
                <div className="flex flex-col">
                    <SidebarItem icon={<AiOutlineDashboard />}>Dashboard</SidebarItem>
                    <SidebarItem icon={<AiOutlineShoppingCart />}>Order Management</SidebarItem>
                    <SidebarItem icon={<AiOutlineUser />}>Customers</SidebarItem>
                    <SidebarItem icon={<AiOutlineTransaction />}>Transactions</SidebarItem>
                    <SidebarItem icon={<AiOutlineTag />}>Brand</SidebarItem>
                    <SidebarItem icon={<AiOutlineInstagram />}>
                        <div onClick={handleInstagramConnect}>Connect Instagram</div>
                    </SidebarItem>
                    <SignOutButton></SignOutButton>
                    {/* Add more nav items as needed */}
                </div>
            </div>
        </div>
    );
}

function SidebarItem({ icon, children }) {
    return (
        <div className="flex items-center mb-5 hover:bg-white hover:text-black rounded cursor-pointer p-2 transition-all duration-150 ease-in-out">
            {icon}
            <span className="ml-2">{children}</span>
        </div>
    );
}

export default AdminDashboard;
