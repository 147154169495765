import React, {useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";

function Explore({isBottomNavOpen, setIsBottomNavOpen, views}) {
    const [hoveredItem, setHoveredItem] = useState(null);
    const menuRef = useRef(); // Create a ref for the menu

    useEffect(() => {
    const handleClickOutside = (event) => {
        // Check if the clicked element is a descendant of the menu
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            // Additionally, check if the target is not a link
            if (!event.target.closest('a')) {
                setIsBottomNavOpen(false);
            }
        }
    };

    // Add event listener when the component is mounted
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Remove the event listener when the component is unmounted
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setIsBottomNavOpen]);

    return (
        <div className={"absolute bottom-0 left-0"}>
            <div className={"absolute bottom-10 left-10 flex flex-col"}>
                <div className={"z-10"} onClick={() => setIsBottomNavOpen(!isBottomNavOpen)}>
                    <button className={'bg-transparent text-white border-none hover:cursor-pointer p-0 text-xl hover:font-bold'}
                            style={{userSelect: 'none'}}
                            ref={menuRef}>Explore</button>
                </div>
                <div className={`absolute bottom-0 left-0 transform ${isBottomNavOpen ? "translate-x-0" : "-translate-x-full"} transition-transform duration-500 ease-in-out`}>
                    {isBottomNavOpen && (
                        <ul className={'list-none pl-0 pb-1'}>
                            {
                                views
                                    .filter(view => view.id !== 'stats-room')
                                    .map((view, index) => (
                                    <li className={'mb-4 w-full whitespace-nowrap'} key={view.id}> {/* It's important to add a unique key for each item */}
                                        <Link to={`/garyharris/${view.id}`}
                                              onMouseEnter={() => setHoveredItem(index)}
                                              onMouseLeave={() => setHoveredItem(null)}
                                              className={`text-white font-bold text-2xl ${hoveredItem !== null && hoveredItem !== index ? 'text-opacity-40' : ''} ${hoveredItem === index ? 'cursor-pointer' : ''} transition duration-300 ease-in-out`}
                                              style={{ textDecoration: 'none' }}
                                        >
                                            {view.name}
                                        </Link>
                                    </li>
                                ))
                            }
                        </ul>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Explore
