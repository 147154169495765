// import 'animate.css'
import { } from 'three'
// import '../../styles/NavBtnStyle.css'
import '../../styles/scene3d.css'
import { RoomProvider } from './Contexts'
import App from './Mesh'



const Scene3D = ({ ...params }) => {
  return (
    <>
        <RoomProvider>
          <App
            model={params.model}
          />
        </RoomProvider>

    </>
  )
}

export default Scene3D
