import React from 'react';

const DesktopOnly = ({ children }) => {
    const [isMobile, setIsMobile] = React.useState(false);

    React.useEffect(() => {
        // Check if screen width is less than a desktop size
        const checkIfMobile = () => window.innerWidth < 1200; // You can adjust this value
        setIsMobile(checkIfMobile());
        // Add event listener to re-check on window resize
        window.addEventListener('resize', () => setIsMobile(checkIfMobile()));
        return () => window.removeEventListener('resize', () => setIsMobile(checkIfMobile()));
    }, []);

    if (isMobile) {
        return (
            <div style={{ padding: 20, textAlign: 'center', marginTop: '20%' }}>
                <h1>Please open this app on a desktop or laptop</h1>
                <p>We've designed this experience specifically for larger screens.</p>
            </div>
        );
    }

    return <>{children}</>;
};

export default DesktopOnly;