import React, {useEffect, useState} from "react";
import '../styles/Store.css';
import TopNavBar from "./TopNavBar";

import '../styles/PlayAnimation.css';
import SocialFeeds from "./SocialFeeds";

// @ts-ignore
import PhotoRoom from "./PhotoRoom";
import TheaterRoom from "./TheaterRoom";
import Explore from "./Explore";
import Washroom from "./Washroom";
import {SquareLightbulbButton} from "./Icons";
import Closet from "./Closet";
import Loading from "./Loading";
import AudioModal from "./AudioModal";
import Gym from "./Gym";

// @ts-ignore
function View({ viewData, productData, views }) {
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const [isBottomNavOpen, setIsBottomNavOpen] = useState(false);
    const [backgroundImageIndex, setBackgroundImageIndex] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);

    const backgroundImages = [
        `url(${viewData.backgroundImage})`,
        'url(/images/garyharris/GH_1_green.png)',
        'url(/images/garyharris/GH_1_purple.png)',
        'url(/images/garyharris/GH_1_cyan.png)',
    ];

    const preloadImages = (imageUrls: any[]) => {
        const loadImage = (url: string) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = url;
                img.onload = () => resolve(url);
                img.onerror = () => reject(new Error(`Load failed for ${url}`));
            });
        };

        return Promise.all(imageUrls.map(loadImage));
    };

    useEffect(() => {
        const imageUrls = [
            '/images/garyharris/GH_1_green.png',
            '/images/garyharris/GH_1_purple.png',
            '/images/garyharris/GH_1_cyan.png',
            '/images/garyharris/GH_1_orange.png',
            '/images/garyharris/washroom/shower/Washroom_Shower.png',
            '/images/garyharris/washroom/shelves/Washroom_Shelf.png',
            '/images/garyharris/washroom/sink/Washroom_Sink.png',
            '/images/garyharris/washroom/tub/Washroom_Tub_Day.png',
            '/images/garyharris/washroom/tub/Washroom_Tub_Night.png',
        ].map(url => url.startsWith('url(') ? url.slice(5, -2) : url); // Adjust URLs if needed

        preloadImages(imageUrls).then(() => {
            setIsLoaded(true);
        }).catch(error => console.error("Failed to preload images", error));

    }, []);

    // Conditional rendering based on isLoaded
    if (!isLoaded) {
        return (<Loading show={!isLoaded} />);
    }

    const changeBackgroundImage = () => {
        setBackgroundImageIndex((prevIndex) => (prevIndex + 1) % backgroundImages.length);
    };

    const toggleShareModal = () => {
        setIsShareModalOpen(!isShareModalOpen);
    };


    return (
        <>
        <TopNavBar onShareClick={toggleShareModal}/>
            {viewData.id !== 'washroom' && viewData.id !== 'closet' && viewData.id != 'gym' &&
        <div className={`imageContainer overflow-y-hidden relative transition duration-500 ease-in-out ${isBottomNavOpen ? 'blur-md -z-50' : ''}`} style={{ backgroundImage: backgroundImages[backgroundImageIndex] }}>

            {viewData.id === 'office' && (<SocialFeeds />)}

            {viewData.id === 'gallery' && (<PhotoRoom />)}

            {viewData.id === 'cinema' && (<TheaterRoom viewData={viewData} />)}

            {
                viewData.id === 'statsRoom' && (
                    <div className={"backdrop-blur-sm"}>
                        <img src={"/images/garyharris/Table1.png"} />
                    </div>
                )
            }

            {viewData.id === 'lounge' && (
                <div>
                    <SquareLightbulbButton className={'absolute h-10 w-10 left-[82%] top-[37%]'} onClick={changeBackgroundImage}/>
                </div>

                )
            }

        </div>
            }
            {viewData.id === 'washroom' && (<div className={`relative transition duration-500 ease-in-out ${isBottomNavOpen ? 'blur-md -z-50' : ''}`}><Washroom /></div>)}
            {viewData.id === 'closet' && (<div className={`relative transition duration-500 ease-in-out ${isBottomNavOpen ? 'blur-md -z-50' : ''}`}><Closet /></div>)}
            {viewData.id === 'gym' && (<div className={`relative transition duration-500 ease-in-out ${isBottomNavOpen ? 'blur-md -z-50' : ''}`}><Gym /></div>)}
        <Explore isBottomNavOpen={isBottomNavOpen} setIsBottomNavOpen={setIsBottomNavOpen} views={views}/>
        </>
    );
}

export default View;


