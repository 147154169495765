// src/components/ComingSoon.js
import React, { useState } from 'react';
// import CountdownTimer from './CountdownTimer';

//@ts-ignore
const ComingSoon = ({ onAuthenticate }) => {
    const [password, setPassword] = useState('');

    //@ts-ignore
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    //@ts-ignore
    const handleFormSubmit = async (event) => {
        event.preventDefault();

        // Define the URL of your /authenticate endpoint
        const authUrl = "https://oah20r5icf.execute-api.us-east-2.amazonaws.com/prod/authenticate";

        try {
            console.log("handle form submit");
            const response = await fetch(authUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ password }),
            });

            const data = await response.json();
            console.log(data.authenticated);

            if (data.authenticated) {
                onAuthenticate();
            } else {
                alert('Incorrect password');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred while attempting to authenticate');
        }
    };

    return (
        <div className="min-h-screen bg-black flex flex-col justify-center items-center text-white coming-soon space-y-16">
            <form onSubmit={handleFormSubmit} className="flex items-center rounded-full border border-white p-1">
                <input
                    type="password"
                    value={password}
                    onChange={handlePasswordChange}
                    placeholder="Enter password"
                    required
                    className="flex-grow rounded-l-full bg-opacity-40 bg-black text-white placeholder-white px-4 py-2 outline-none"
                />
                <button type="submit" className="bg-white text-black rounded-r-full px-4 py-2 hover:cursor-pointer">Access</button>
            </form>
            {/*
            <div className="mt-16 text-center">
                <CountdownTimer targetDate="2024-02-16T00:00:00" />
                <h1>Until Gameday</h1>
            </div>
            */
            }
        </div>
    );
};

export default ComingSoon;
