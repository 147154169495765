import React, {useEffect, useRef, useState} from "react";
import '../styles/Store.css';
import '../styles/Washroom.css';
import SquareModal from "./SquareModal";
import {SquareLightbulbButton, TriangleIconLeft, TriangleIconRight} from "./Icons";
import Loading from "./Loading";

function Washroom() {

    const defaultView = 0;

    let washroomViews = {
        shelf : {
            backgroundImageSrc: "/images/garyharris/washroom/shelves/Washroom_Shelf.png",
            modals: [
                {
                    position: "left-[29%] top-[32%]",
                    zoomImageUrl: "/images/garyharris/washroom/shelves/Washroom_Shelf_TF_Candles.png",
                    zoomInVideoUrl: "/images/garyharris/washroom/zoom/Zoom_In_Candles.mp4",
                    zoomOutVideoUrl: "/images/garyharris/washroom/zoom/Zoom_Out_Candles.mp4",
                    products: [
                        {
                            title: "Tom Ford Candle Set",
                            price: "500",
                            description: "A scent of oud notes, exotic woods, \n" +
                                "and warm amber for the home.\n \n" +
                                "The fabulous Private Blend scent \n" +
                                "in a luxe candle for the home.",
                            images: [
                                '/images/products/resized/candle_0.png',
                                '/images/products/resized/candle_1.png',
                            ]
                        },
                    ],
                    modalId: 0
                },
                {
                    position: "left-[46%] top-[48%]",
                    zoomImageUrl: "/images/garyharris/washroom/shelves/Washroom_Shelf_Skincare.png",
                    zoomInVideoUrl: "/images/garyharris/washroom/zoom/Zoom_In_Skincare.mp4",
                    zoomOutVideoUrl: "/images/garyharris/washroom/zoom/Zoom_Out_Skincare.mp4",
                    products: [
                        {
                            title: "Aesop Skincare Set",
                            price: "200",
                            description: "A concentrated blend of precious plant extracts\n" +
                                "delivering potent doses of vitamins and fatty acids. \n" +
                                "Relieves, nourishes and replenishes parched skin.",
                            images: [
                                '/images/products/resized/skincare_1.png',
                                '/images/products/resized/skincare_2.png',
                            ]
                        },
                    ],
                    modalId: 1
                },
                {
                    position: "left-[75%] top-[48%]",
                    zoomImageUrl: "/images/garyharris/washroom/shelves/Washroom_Shelf_Cologne1.png",
                    zoomInVideoUrl: "/images/garyharris/washroom/zoom/Zoom_In_Cologne.mp4",
                    zoomOutVideoUrl: "/images/garyharris/washroom/zoom/Zoom_Out_Cologne.mp4",
                    products: [
                        {
                            title: "Millésime Impérial",
                            price: "425",
                            description: "Millésime Impérial\n\n" +
                                "Floral & Crisp\n\n" +
                                "A fresh, stylish and elegant perfume,\n " +
                                "Millésime Impérial is a blend of marine accord and\n " +
                                "iris with sparkling hesperidic top notes.",
                            images: [
                                '/images/products/resized/cologne_0.png',
                                '/images/products/resized/cologne_1.png',
                            ]
                        },
                        {
                            title: "Millésime 1849",
                            price: "495",
                            description: "Millésime 1849 \n\n" +
                                "Reawakened from the House of Creed's esteemed perfume vault,\n" +
                                " Millésime 1849 is a rich, warm fragrance that fascinates with a graceful \n" +
                                "modern sophistication.",
                            images: [
                                '/images/products/washroom/resize/cologne_3.png',
                                '/images/products/washroom/resize/cologne_4.png',
                            ]
                        },
                        {
                            title: "Creed Viking",
                            price: "495",
                            description: "Viking \n\n" +
                                "Spicy, Fougère, Aromatic & Fresh" +
                                "A bold and fiery men's fragrance,\n" +
                                "Viking bottles the fearless spirit of boundless exploration\n " +
                                "for the modern man who goes against the grain.",
                            images: [
                                '/images/products/washroom/resize/cologne_5.png',
                                '/images/products/washroom/resize/cologne_6.png',
                            ]
                        },
                        {
                            title: "Creed Green Neroli",
                            price: "360",
                            description:
                                "Citrus, Fougère, Aromatic & Crisp\n\n" +
                                "Inspired by the abundant aromatic blossoms \n" +
                                "that thrive in the Mediterranean summer, \n" +
                                "Green Neroli is a hypnotic, herbaceous and fresh citrus fragrance.",
                            images: [
                                '/images/products/washroom/resize/cologne_7.png',
                                '/images/products/washroom/resize/cologne_8.png',
                            ]
                        }
                    ],
                    modalId: 2
                },
                {
                    position: "left-[46%] top-[90%]",
                    zoomImageUrl: "/images/garyharris/washroom/shelves/Washroom_Shelf_Jersey.png",
                    zoomInVideoUrl: "/images/garyharris/washroom/zoom/Zoom_In_Jersey.mp4",
                    zoomOutVideoUrl: "/images/garyharris/washroom/zoom/Zoom_Out_Jersey.mp4",
                    products: [
                        {
                            title: "Gary Harris - Orlando Magic Jersey",
                            price: "150",
                            description: "Men's Orlando Magic Fanatics Branded\n" +
                                " Black Fast Break Custom Jersey - Icon Edition",
                            images: [
                                '/images/products/resized/jersey_0.png',
                                '/images/products/resized/jersey_1.png',
                            ]
                        },
                    ],
                    modalId: 3
                },
                {
                    position: "left-[16%] top-[90%]",
                    zoomImageUrl: "/images/garyharris/washroom/shelves/Washroom_Shelf_Kaws.png",
                    zoomInVideoUrl: "/images/garyharris/washroom/zoom/Zoom_In_Kaws.mp4",
                    zoomOutVideoUrl: "/images/garyharris/washroom/zoom/Zoom_Out_Kaws.mp4",
                    products: [
                        {
                            title: "Kaws",
                            price: "200",
                            description: "Lorem ipsum dolor sit amet,\n" +
                                "consectetur adipiscing elit. Nulla vehicula.",
                            images: [
                                '/images/products/resized/kaw_0.png',
                                '/images/products/resized/kaw_1.png'
                            ]
                        },
                    ],
                    modalId: 4
                },
            ],
        },
        shower : {
            backgroundImageSrc: "/images/garyharris/washroom/shower/Washroom_Shower.png",
            modals: [
                {
                    position: "left-[12%] top-[65%]",
                    zoomImageUrl: null,
                    zoomInVideoUrl: null,
                    zoomOutVideoUrl: null,
                    products: [
                        {
                            title: "Scrooge McDuck LV Supreme Painting",
                            price: "4000",
                            description: "Acrylic on Canvas, 28x24in\n" +
                                "This work inspired by Louis Vuitton.\n" +
                                "Louis Vuitton is one of the most charming status symbols.\n" +
                                "Perseverance and dedication do not leave anyone indifferent.",
                            images: [
                                '/images/products/resized/painting.png'
                            ]
                        },
                    ],
                    modalId: 0
                },
                {
                    position: "left-[45%] top-[60%]",
                    zoomImageUrl: null,
                    zoomInVideoUrl: null,
                    zoomOutVideoUrl: null,
                    products: [
                        {
                            title: "Chrome Hearts Robe [white] Terry CH+",
                            price: "10000",
                            description: "Chrome Hearts Robe\n\n" +
                                "Body: 5% Poly 95% Cotton. Lining: 100% cotton.\n" +
                                "Made in United States\n" +
                                "241014M180021",
                            images: [
                                '/images/products/resized/robe_0.png',
                                '/images/products/resized/robe_1.png'
                            ]
                        },
                    ],
                    modalId: 1
                }
            ],
        },
        tub : {
            backgroundImageSrc: "/images/garyharris/washroom/tub/Washroom_Tub_Day.png",
            modals: [],
        },
        sink : {
            backgroundImageSrc: "/images/garyharris/washroom/sink/Washroom_Sink.png",
            modals: [
                {
                    position: "left-[34%] top-[67%]",
                    zoomImageUrl: "/images/garyharris/washroom/sink/Washroom_Sink_Manscaped.png",
                    zoomInVideoUrl: "/images/garyharris/washroom/zoom/Zoom_In_Mascaped.mp4",
                    zoomOutVideoUrl: "/images/garyharris/washroom/zoom/Zoom_Out_Mascaped.mp4",
                    products: [
                        {
                            title: "Manscaped Shaving Kit",
                            price: "100",
                            description: "Packed with tools, trimmers, and all the formulations\n " +
                                "your body could ever ask for.\n" +
                                "This luxury full-body grooming kit has everything\n" +
                                "you need to stay on top of your manly rituals.",
                            images: [
                                '/images/products/washroom/manscaped_0.png',
                                '/images/products/washroom/manscaped_1.png',
                                '/images/products/washroom/manscaped_2.png',
                                '/images/products/washroom/manscaped_3.png',
                            ]
                        },
                    ],
                    modalId: 0
                },
                {
                    position: "left-[65%] top-[67%]",
                    zoomImageUrl: "/images/garyharris/washroom/sink/Washroom_Sink_Toothbrush_Gilette.png",
                    zoomInVideoUrl: "/images/garyharris/washroom/zoom/Zoom_In_Toothbrush.mp4",
                    zoomOutVideoUrl: "/images/garyharris/washroom/zoom/Zoom_Out_Toothbrush.mp4",
                    products: [
                        {
                            title: "Gillette Razor Kit",
                            price: "75",
                            description: "Lorem ipsum dolor sit amet,\n" +
                                "consectetur adipiscing elit. Nulla vehicula.",
                            images: [
                                '/images/products/washroom/gillette_0.png',
                            ]
                        },
                        {
                            title: "Sonicare Brush Kit",
                            price: "150",
                            description: "Lorem ipsum dolor sit amet,\n" +
                                "consectetur adipiscing elit. Nulla vehicula.",
                            images: [
                                '/images/products/washroom/sonicare_0.png',
                            ]
                        },
                    ],
                    modalId: 1
                },
                {
                    position: "left-[82%] top-[67%]",
                    zoomImageUrl: "/images/garyharris/washroom/sink/Washroom_Sink_Wine.png",
                    zoomInVideoUrl: "/images/garyharris/washroom/zoom/Zoom_In_Wine.mp4",
                    zoomOutVideoUrl: "/images/garyharris/washroom/zoom/Zoom_Out_Wine.mp4",
                    products: [
                        {
                            title: 'Petrus Pomerol Wine',
                            price: '5000',
                            description: "Petrus 2018 is considered a truly exceptional\n" +
                                "vintage of Chateau Petrus, " +
                                "often compared to \n" +
                                "the legendary 1998 vintage.\n" +
                                "This wine received a perfect score of\n" +
                                "100/100 points from both James Suckling\n" +
                                "and Robert Parker's Wine Advocate.",
                            images: [
                                '/images/products/resized/cropped-wine_0.png',
                            ]
                        }
                    ],
                    modalId: 2
                },
            ],
        },
        key: function (n) {
            return this[Object.keys(this)[n]];
        }
    }

    const videoSources = [
        '/images/garyharris/washroom/zoom/Zoom_In_Candles.mp4',
        '/images/garyharris/washroom/zoom/Zoom_Out_Candles.mp4',
        '/images/garyharris/washroom/zoom/Zoom_In_Skincare.mp4',
        '/images/garyharris/washroom/zoom/Zoom_Out_Skincare.mp4',
        '/images/garyharris/washroom/zoom/Zoom_In_Mascaped.mp4',
        '/images/garyharris/washroom/zoom/Zoom_Out_Mascaped.mp4',
        '/images/garyharris/washroom/zoom/Zoom_In_Toothbrush.mp4',
        '/images/garyharris/washroom/zoom/Zoom_Out_Toothbrush.mp4',
        '/images/garyharris/washroom/zoom/Zoom_In_Wine.mp4',
        '/images/garyharris/washroom/zoom/Zoom_Out_Wine.mp4',
        '/images/garyharris/washroom/zoom/Zoom_In_Kaws.mp4',
        '/images/garyharris/washroom/zoom/Zoom_Out_Kaws.mp4',
        '/images/garyharris/washroom/zoom/Zoom_In_Cologne.mp4',
        '/images/garyharris/washroom/zoom/Zoom_Out_Cologne.mp4',
        '/images/garyharris/washroom/zoom/Zoom_In_Jersey.mp4',
        '/images/garyharris/washroom/zoom/Zoom_Out_Jersey.mp4',
    ];

    const [view, setView] = useState(defaultView);
    const [backgroundImage, setBackgroundImage] = useState(washroomViews.key(defaultView).backgroundImageSrc);
    const [activeModal, setActiveModal] = useState(null);
    const [isDaytime, setIsDayTime] = useState(true);

    const videoContainerRef = useRef(null); // Ref for the div to insert videos into

    const changeBackgroundImage = () => {
        setIsDayTime(!isDaytime);
        if(isDaytime) {
            washroomViews["tub"].backgroundImageSrc = "/images/garyharris/washroom/tub/Washroom_Tub_Day.png";
        } else {
            washroomViews["tub"].backgroundImageSrc = "/images/garyharris/washroom/tub/Washroom_Tub_Night.png";
        }
        setBackgroundImage(washroomViews["tub"].backgroundImageSrc);
    };

    const preloadImages = (imageUrls) => {
        const loadImage = (url) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = url;
                img.onload = () => resolve(url);
                img.onerror = () => reject(new Error(`Load failed for ${url}`));
                img.loading = 'eager';
            });
        };

        return Promise.all(imageUrls.map(loadImage));
    };

    const preloadVideos = () => {
        const loadVideo = (url) => {
            return new Promise((resolve, reject) => {
                const video = document.createElement('video');
                video.src = url;
                video.preload = 'auto';
                video.style.display = 'none'; // Initially, videos are not displayed
                video.style.height = '100%';
                video.style.width = '100%';
                video.style.position = 'absolute';
                video.style.objectFit = 'cover';
                video.id = `preloaded-video-${url}`; // Assign unique ID
                videoContainerRef.current.appendChild(video); // Append video to the container
            });
        }
        return Promise.all(videoSources.map(loadVideo));
    }

    const loadOtherResources = () => {
        preloadVideos().then(() => {
        }).catch(error => console.log('Error loading videos', error));
    }

    useEffect(() => {
        const innerImages = [
            '/images/garyharris/washroom/sink/Washroom_Sink_Manscaped.png',
            '/images/garyharris/washroom/sink/Washroom_Sink_Toothbrush_Gilette.png',
            '/images/garyharris/washroom/sink/Washroom_Sink_Wine.png',
            '/images/garyharris/washroom/shelves/Washroom_Shelf_TF_Candles.png',
            '/images/garyharris/washroom/shelves/Washroom_Shelf_Skincare.png',
            '/images/garyharris/washroom/shelves/Washroom_Shelf_Cologne1.png',
            '/images/garyharris/washroom/shelves/Washroom_Shelf_Jersey.png',
            '/images/garyharris/washroom/shelves/Washroom_Shelf_Kaws.png',
        ];

        preloadImages(innerImages).then(() => {
            loadOtherResources();
        }).catch(error => console.error("Loaded landing image", error));
    }, []);

    const playVideo = (videoId, zoomImageUrl, onVideoPlay = () => {}) => {
        let video = document.getElementById(`preloaded-video-${videoId}`);

        //Create video element incase pre-load failed
        if(!video) {
            video = document.createElement('video');
            video.src = videoId;
            video.preload = 'auto';
            video.style.display = 'none'; // Initially, videos are not displayed
            video.style.height = '100%';
            video.style.width = '100%';
            video.style.position = 'absolute';
            video.style.objectFit = 'cover';
            video.id = `preloaded-video-${videoId}`; // Assign unique ID
            videoContainerRef.current.appendChild(video); // Append video to the container
        }

        const videoContainer = document.getElementById('videoDiv');
        const imageContainer = document.getElementById('imageContainerDiv');
        if (video) {
            const playPromise = video.play();
            playPromise.then(() => {
                onVideoPlay();
                imageContainer.style.display = 'none';
                videoContainer.style.display = 'flex';
                video.style.display = 'flex'; // Make the video visible
            });
            video.addEventListener('ended', () => {
                imageContainer.style.display = 'flex';
                video.style.display = 'none'; // Hide the video when it ends
                videoContainer.style.display = 'none';
            });
        }
    };

    const toggleNextView = (direction) => {
        let nextView;
        if(direction === 'left') {
            nextView = view - 1;
            if(nextView < 0) {
                nextView = 3;
            }
        } else {
            nextView = view + 1;
            if(nextView > 3) {
                nextView = 0;
            }
        }
        setView(nextView);
        setBackgroundImage(washroomViews.key(nextView).backgroundImageSrc);
    }

    const toggleModal = (modalData) => {
        const showModal = () => {
            setActiveModal(modalData.modalId); // Only show the modal after ensuring the video plays
        };

        if(activeModal !== null) {
            setActiveModal(null);
            const zoomOutVideoUrl = modalData.zoomOutVideoUrl ?? null;
            if(modalData.zoomOutVideoUrl !== null) {
                playVideo(zoomOutVideoUrl, null);
                setBackgroundImage(washroomViews.key(view).backgroundImageSrc);
            } else {
                setBackgroundImage(washroomViews.key(view).backgroundImageSrc);
            }
        } else {
            const zoomImageUrl = modalData.zoomImageUrl ?? null;
            if(zoomImageUrl !== null) {
                playVideo(modalData.zoomInVideoUrl, zoomImageUrl, showModal);
                setBackgroundImage(zoomImageUrl);
                return;
            }
            showModal();
        }
    };

    return (
        <>
            <div ref={videoContainerRef} id='videoDiv' className={'w-full h-screen overflow-hidden z-0'} style={{display: "none"}}>

            </div>
            <div id={'imageContainerDiv'} className={`imageContainer overflow-y-hidden`} style={{backgroundImage: `url(${backgroundImage})`}}>
                <>
                    {   activeModal === null && (
                        washroomViews.key(view).modals.map((modalData, index) => {
                            return (
                                <SquareModal position={modalData.position} buttonSize={'h-10 w-10'}
                                             toggleModal={() => toggleModal(modalData)}
                                             modalData={modalData}
                                />
                            )
                        }))
                    }
                    {
                        activeModal !== null && (
                            <SquareModal toggleModal={() => toggleModal(washroomViews.key(view).modals[activeModal])}
                                         activeModalId={activeModal}
                                         modalData={washroomViews.key(view).modals[activeModal]} />
                        )
                    }
                </>

                {
                    //Tub
                    view === 2 && (
                        <>
                            <SquareLightbulbButton  className={'absolute h-10 w-10 left-[60%] top-[40%] z-50'} onClick={changeBackgroundImage}/>
                        </>
                    )
                }
                {activeModal === null &&
                    <>
                        <TriangleIconRight className={"cursor-pointer absolute h-14 w-14 left-[95%] top-[60%]"} onClick={() => toggleNextView('right')}/>
                        <TriangleIconLeft className={"cursor-pointer absolute h-14 w-14 left-[2%] top-[60%]"} onClick={() => toggleNextView('left')} />
                    </>
                }
            </div>
        </>
    )
}

export default Washroom;