import React, {useState} from 'react';
import {InstagramAppIcon, IPhoneOutline, NewsAppIcon, TwitterAppIcon, WebBrowserOutline} from "./Icons";
import {DotsHorizontalIcon, HeartIcon, PaperAirplaneIcon, BookmarkIcon} from "@heroicons/react/outline";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-regular-svg-icons';
import {Timeline} from 'react-twitter-widgets';
import '../styles/socialfeeds.css';


function SocialFeeds() {
    const [selectedFeed, setSelectedFeed] = useState('instagram');

    return (
        <>
            <div className="absolute left-[7.5rem] p-4 rounded-xl h-fit border border-opacity-5">
                <div className="flex flex-col justify-center p-1 bg-opacity-10 bg-gray-300 backdrop-blur-md rounded-xl h-full border border-opacity-5">
                    <ul className="list-none p-0">
                        <li className="flex mb-4 items-center justify-center w-12 h-12 relative group hover:cursor-pointer" onClick={() => setSelectedFeed('instagram')}>
                            <InstagramAppIcon className="transition-transform transform-gpu" />
                            <span className="absolute left-20 bg-opacity-50 bg-black text-white rounded px-4 py-2 invisible group-hover:visible">Instagram</span>
                        </li>
                        <li className="flex mb-4 items-center justify-center w-12 h-12 relative group hover:cursor-pointer" onClick={() => setSelectedFeed('twitter')}>
                            <TwitterAppIcon className="transition-transform transform-gpu w-11 h-11" />
                            <span className="absolute left-20 bg-opacity-50 bg-black text-white rounded px-4 py-2 invisible group-hover:visible">Twitter</span>
                        </li>
                        <li className="flex items-center justify-center w-12 h-12 relative group border-l border-opacity-40 hover:cursor-pointer" onClick={() => setSelectedFeed('articles')}>
                            <NewsAppIcon className="transition-transform transform-gpu w-9 h-9" />
                            <span className="absolute left-20 bg-opacity-50 bg-black text-white rounded px-4 py-2 invisible group-hover:visible">Articles</span>
                        </li>
                    </ul>
                </div>
            </div>
            {selectedFeed === 'instagram' && <IGFeed />}
            {selectedFeed === 'twitter' && <TwitterFeed />}
            {selectedFeed === 'articles' && <Articles />}
        </>

    )
}

function TwitterFeed() {

    return (
        <div>
            <IPhoneOutline className={"svg-outline"}/>
            <div className={"timeline-div"}>

            <Timeline dataSource={{
                    sourceType: 'profile',
                    screenName: 'thats_G_'
                }}
                          options={{
                              theme: 'light'
                          }}
                    />
            </div>
        </div>
    )
}
function IGFeed() {
    const postList = [
        "/images/igdemophotos/ig_1.jpeg",
        "/images/igdemophotos/ig_2.jpeg",
        "/images/igdemophotos/ig_3.jpeg"
    ];

    return (
        <>
            <IPhoneOutline className={"svg-outline"}/>
            <div className={"timeline-div"}>
                {postList.map((imgSrc) => {
                    return <Post imgSrc={imgSrc}/>
                    })
                }
            </div>
        </>)
}

function Post({imgSrc}) {
    return (
        <div className="bg-white border rounded-sm">
            <div className="flex items-center p-4">
                <div className="avatar mr-3">
                    <div className="rounded-full w-10 h-10 overflow-hidden">
                        <img alt="" src="https://w0.peakpx.com/wallpaper/180/958/HD-wallpaper-black-plain.jpg" />
                    </div>
                </div>
                <p className="flex-1 text-sm font-semibold">thats_g_</p>
                <DotsHorizontalIcon className={"h-5"} />
            </div>
            <img className="w-full" src={imgSrc} alt="" />
            <div className="flex justify-between px-2 pt-2">
                <div className="flex space-x-4 items-center">
                    <HeartIcon className="h-6" />
                    <FontAwesomeIcon className="cursor-pointer scale-x-[-1] " icon={faComment} size="lg" />
                    <PaperAirplaneIcon className="h-6 rotate-[60deg] mb-1" />
                </div>
                <BookmarkIcon className="h-6" />
            </div>
            <p className="px-2 mt-1 truncate">
                <p className="font-semibold mb-1 text-sm">5023 likes</p>
                <span className="font-semibold mr-1 text-sm">thats_g_</span>10piece
            </p>
            <p className="px-2 text-sm mb-1 text-gray-400 cursor-pointer ">View all 116 comments</p>
        </div>
    )
}

function Articles() {
    return (
        <>
            <WebBrowserOutline className={"backdrop-blur-sm rounded-t-3xl absolute top-[17%] w-[50%]"}/>
            <iframe title={"Articles"} src={"https://bleacherreport.com/articles/2735361"} width="50%" height="60%" className={"mt-20 rounded-b-3xl border-none bg-white"}></iframe>
        </>
    )
}

export default SocialFeeds;
