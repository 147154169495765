import React from "react"
import {SignIn} from "@clerk/clerk-react";

function CustomLogin() {
    return (
        <div className="flex justify-center items-center min-h-screen">
            <SignIn></SignIn>
        </div>);
}

export default CustomLogin

