import { useEffect, useState, lazy } from 'react';
import { Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import { stores } from './StoreData';
import Home from "./components/Home";
import Loading from "./components/Loading";
import './index.css';

import {
    ClerkProvider,
} from "@clerk/clerk-react";
import Store from "./components/Store";

import AdminDashboard from "./components/AdminDashboard";
import ComingSoon from "./components/ComingSoon";
import CustomLogin from "./components/CustomLogin";
import CustomSignUp from "./components/CustomSignUp";
import DemoShop from "./components/DemoShop";

const LoadingHandler = () => {
    const [loaded, setLoaded] = useState(false);
    const location = useLocation();

    useEffect(() => {
        setLoaded(false);
        const timeoutId = setTimeout(() => {
            setLoaded(true);
        }, 500);  
        return () => clearTimeout(timeoutId);  // Clear the timeout if the component is unmounted
    }, [location]);  // Re-run this effect whenever the location changes

    if (!loaded) {
        return (
            <Loading show={!loaded} />
        );
    }

    return (
        <Routes>
            <Route path="/" element={<Home />} />
            {stores.map(store => (
                <Route key={store.id} path={`/${store.id}/*`} element={<Store storeData={store} />} />
            ))}
            <Route path="/login" element={<CustomLogin />} />
            <Route path="/sign-up" element={<CustomSignUp />} />
            <Route path="/shop" element={<DemoShop />} />
            <Route path="/admin" element={<AdminDashboard />} />
        </Routes>
    );
};

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const CLERK_PUBLISHABLE_KEY = "pk_test_ZHJpdmVuLWFudC01NC5jbGVyay5hY2NvdW50cy5kZXYk";

    console.log("Current environment: " + process.env.NODE_ENV);

    useEffect(() => {
        // Check if the user is already authenticated
        const isAlreadyAuthenticated = sessionStorage.getItem('isAuthenticated');
        if (isAlreadyAuthenticated === 'true') {
            setIsAuthenticated(true);
        }
    }, []);

    const handleAuthenticate = () => {
        setIsAuthenticated(true);
        sessionStorage.setItem('isAuthenticated', String(true));
    };

    if (!isAuthenticated && process.env.NODE_ENV !== "development") {
        return <ComingSoon onAuthenticate={handleAuthenticate} />;
    }


    return (
        <ClerkProvider publishableKey={CLERK_PUBLISHABLE_KEY}>
            <Router>
                <LoadingHandler />
            </Router>
        </ClerkProvider>
    );
}

export default App;
