import React from "react";

import {Link} from "react-router-dom";
import {stores} from "../StoreData";

function Home() {
    return (
            <div className="bg-black text-white w-full h-screen overflow-hidden">
                <video autoPlay loop muted preload={"auto"} className="w-full h-full object-cover absolute z-0">
                    <source src="/Final003.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="absolute inset-0 flex flex-col items-center justify-center p-4 bg-black bg-opacity-50">
                    <h1 className="text-3xl mb-2">GAMEDAY</h1>
                    <Link to="/garyharris/lounge" className="px-6 py-2 border border-solid border-white text-white rounded transition ease-in duration-150 hover:font-bold hover:bg-gradient-to-r hover:from-black hover:to-gray-700" style={{ textDecoration: 'none' }}>View Player Spaces</Link>
                </div>
            </div>
    )
}

{/*
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-4">
                {stores.map((store) => (
                    <Link key={store.id} to={`/${store.id}`} className="block relative">
                        <img src={store.previewImage} alt={store.playerName} className="w-full h-56 object-cover" />
                        <div className="absolute inset-0 flex items-center justify-center p-2 bg-black bg-opacity-50 text-white text-center transition duration-300 hover:font-bold">{store.playerName}</div>
                    </Link>
                ))}
            </div>
            */}
export default Home;
