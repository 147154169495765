import React, {useEffect, useRef, useState} from "react";
import SquareModal from "./SquareModal";
import {SquareLightbulbButton, TriangleIconLeft, TriangleIconRight} from "./Icons";

function Gym() {

    const defaultView = 0;

    let gymViews = {
        court : {
            backgroundImageSrc: "/images/garyharris/gym/gym_0.png",
            modals: [
                {
                    position: "left-[76%] top-[70%]",
                    products: [
                    ],
                    videos: [
                        {
                            title: "Summer Workout Guide\n",
                            description: "45 minutes 22 seconds \n\n" +
                                "Summer workout",
                            src: "https://www.youtube.com/watch?v=JrkrhaBDsuY"
                        },
                        {
                            title: "Preparing your shot\n",
                            description: "32 minutes 15 seconds \n\n" +
                                "Create space is one things, but how do you\n" +
                                "prepare to be ready to fire, what are the\n" +
                                "mechanics, what are the trade-offs.",
                            src: "https://www.youtube.com/watch?v=awh1JbCP400"
                        },
                        {
                            title: "Hand eye\n",
                            description: "20 minutes 12 seconds\n\n" +
                                "Building up your fast twitch will change how\n" +
                                "you move but the coordination and quickness\n" +
                                "comes from small repetitive actions.",
                            src: "https://www.youtube.com/watch?v=myO-QpTLvas"
                        }
                    ],
                    modalId: 0
                },
            ],
        },
        key: function (n) {
            return this[Object.keys(this)[n]];
        }
    }

    const [view, setView] = useState(defaultView);
    const [backgroundImage, setBackgroundImage] = useState(gymViews.key(defaultView).backgroundImageSrc);
    const [activeModal, setActiveModal] = useState(null);

    const videoContainerRef = useRef(null); // Ref for the div to insert videos into

    const playVideo = (videoId, zoomImageUrl, onVideoPlay = () => {}) => {
        const video = document.getElementById(`preloaded-video-${videoId}`);
        const videoContainer = document.getElementById('videoDiv');
        const imageContainer = document.getElementById('imageContainerDiv');
        if (video) {
            const playPromise = video.play();
            playPromise.then(() => {
                onVideoPlay();
                imageContainer.style.display = 'none';
                videoContainer.style.display = 'flex';
                video.style.display = 'flex'; // Make the video visible
            });
            video.addEventListener('ended', () => {
                imageContainer.style.display = 'flex';
                video.style.display = 'none'; // Hide the video when it ends
                videoContainer.style.display = 'none';
            });
        }
    };

    const toggleModal = (modalData) => {
        const showModal = () => {
            setActiveModal(modalData.modalId); // Only show the modal after ensuring the video plays
        };

        if(activeModal !== null) {
            setActiveModal(null);
            const zoomOutVideoUrl = modalData.zoomOutVideoUrl ?? null;
            if(modalData.zoomOutVideoUrl !== null) {
                playVideo(zoomOutVideoUrl, null);
                setBackgroundImage(gymViews.key(view).backgroundImageSrc);
            } else {
                setBackgroundImage(gymViews.key(view).backgroundImageSrc);
            }
        } else {
            const zoomImageUrl = modalData.zoomImageUrl ?? null;
            if(zoomImageUrl !== null) {
                playVideo(modalData.zoomInVideoUrl, zoomImageUrl, showModal);
                setBackgroundImage(zoomImageUrl);
                return;
            }
            showModal();
        }
    };

    return (
        <>
            <div ref={videoContainerRef} id='videoDiv' className={'w-full h-screen overflow-hidden z-0'} style={{display: "none"}}>

            </div>

            <div id={'imageContainerDiv'} className={`imageContainer overflow-y-hidden`} style={{backgroundImage: `url(${backgroundImage})`}}>
                <>
                    {   activeModal === null && (
                        gymViews.key(view).modals.map((modalData, index) => {
                            return (
                                <SquareModal position={modalData.position} buttonSize={'h-10 w-10'}
                                             toggleModal={() => toggleModal(modalData)}
                                             modalData={modalData} videoList={modalData.videos}
                                />
                            )
                        }))
                    }
                    {
                        activeModal !== null && (
                            <SquareModal toggleModal={() => toggleModal(gymViews.key(view).modals[activeModal])}
                                         activeModalId={activeModal}
                                         modalData={gymViews.key(view).modals[activeModal]} />
                        )
                    }
                </>
                {/*activeModal === null &&
                    <>
                        <TriangleIconRight className={"cursor-pointer absolute h-14 w-14 left-[95%] top-[60%]"} onClick={() => nextView('right')}/>
                        <TriangleIconLeft className={"cursor-pointer absolute h-14 w-14 left-[2%] top-[60%]"} onClick={() => nextView('left')} />
                    </>
                */
                }
            </div>
        </>
    )
}

export default Gym;