import '../styles/Store.css';
import Scene3D from "./3d/Scene";

// locate models at /public/models/NAME.glb

function Closet() {
    return (
        <div id="scene3d-container">
            <Scene3D model="Closet_FINAL" />
        </div>
    );
}

export default Closet;
