import React from 'react';
import '../styles/Loading.css';

// @ts-ignore
const Loading = ({show}) => {
    return (
        <div className={`loading-container${show ? ' show' : ''}`}>
            <span className="loading-text">Gameday</span>
        </div>
    );
}

export default Loading;