import React, {useEffect, useState} from "react";


function DemoShop() {
    const [products, setProducts] = useState([]);
    const [cartId, setCartId] = useState('');
    const [checkoutUrl, setCheckoutUrl] = useState('');
    const [cartItemsCount, setCartItemsCount] = useState(0);

    useEffect(() => {
        // Replace with your actual API endpoint
        async function fetchData() {
            let baseUrl = "https://api.gamedayfits.com";
            if(process.env.NODE_ENV === "development") {
                baseUrl = "http://localhost:3001";
            }

            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            const rawCartRequest = JSON.stringify({
                "storeId": "e5ae5301-85ac-11ee-8bca-06fcd2750fab",
                "userId": "0"
            });

            const cartRequestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: rawCartRequest,
                redirect: 'follow'
            };

            const collectionData = await fetch(`${baseUrl}/products/load-collection?storeId=e5ae5301-85ac-11ee-8bca-06fcd2750fab`)
                .then(response => response.json());
            setProducts(collectionData.data.collection.products.edges);

            const cartData = await fetch(`${baseUrl}/cart/create-cart`, cartRequestOptions).then(response => response.json());
            setCartId(cartData.data.cartCreate.cart.id);
            setCheckoutUrl(cartData.data.cartCreate.cart.checkoutUrl);
        }
        fetchData();

    }, [setProducts, setCartId, setCheckoutUrl]);

    const handleAddToCart = (product) => {
        let baseUrl = "https://api.gamedayfits.com";
        if(process.env.NODE_ENV === "development") {
            baseUrl = "http://localhost:3001";
        }

        console.log('Added to cart:', product);

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "cartId": cartId,
            "merchandiseId": product.node.variants.edges[0].node.id
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${baseUrl}/cart/add-to-cart`, requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));

        setCartItemsCount(cartItemsCount + 1);
    };

    console.log(cartId);
    console.log(products);

    return (
        <div className="container mx-auto p-4">

            <div className="relative">
                <div className="absolute top-0 right-0 p-4 text-4xl">
                    🛒
                    {cartItemsCount > 0 && (
                        <span className="ml-2 bg-red-500 text-white p-1 rounded-full text-xs">
                            {cartItemsCount}
                        </span>
                    )}
                </div>

                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
                    {products.map(product => (
                        <div key={product.node.id} className="max-w-sm rounded overflow-hidden shadow-lg">
                            <img className="w-full" src={product.node.images.edges[0].node.src} alt={product.node.title} />
                            <div className="px-6 py-4">
                                <div className="font-bold text-xl mb-2">{product.name}</div>
                                <p className="text-gray-700 text-base">
                                    ${product.node.variants.edges[0].node.price}
                                </p>
                            </div>
                            <div className="px-6 pt-4 pb-2">
                                <button
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                    onClick={() => handleAddToCart(product)}
                                >
                                    Add to Cart
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="pt-5">
                    <button disabled={cartItemsCount === 0} onClick={() => window.location.href = checkoutUrl}>
                        Checkout
                    </button>
                </div>
            </div>
        </div>
    );
}

export default DemoShop;