import React, {
  useState
} from "react";
import {Link} from "react-router-dom";
import {
  SignedOut,
  useUser
} from "@clerk/clerk-react";
import ShoppingCart from "./ShoppingCart";

// @ts-ignore
function TopNavBar({onShareClick}) {
    const [hoveredItem, setHoveredItem] = useState(null);
    const {
      isSignedIn,
      user,
    } = useUser();

    // @ts-ignore
    return (
        <>
        <div className="absolute items-center top-0 left-0 p-4 z-50 bg-transparent w-[95%]">
            <div className="flex justify-between">
                <ul className="flex space-x-16 list-none text-xl">
                    <li
                        key='home'
                    >
                        <Link to="/"
                            // @ts-ignore
                              onMouseEnter={() => setHoveredItem('home')}
                              onMouseLeave={() => setHoveredItem(null)}
                              className={`text-white ${hoveredItem && hoveredItem !== 'home' ? 'text-opacity-40' : ''} ${hoveredItem === 'home' ? 'font-bold cursor-pointer' : ''} transition duration-300 ease-in-out`}
                              style={{ textDecoration: 'none', userSelect: 'none' }}
                        >
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link to="/shop"
                              // @ts-ignore
                              onMouseEnter={() => setHoveredItem('shop')}
                              onMouseLeave={() => setHoveredItem(null)}
                              className={`text-white ${hoveredItem && hoveredItem !== 'shop' ? 'text-opacity-40' : ''} ${hoveredItem === 'shop' ? 'font-bold cursor-pointer' : ''} transition duration-300 ease-in-out`}
                              style={{ textDecoration: 'none', display: 'none'}}
                        >
                            Shop
                        </Link>
                    </li>
                    <li
                        style={{display: 'none'}}
                        key='share'
                        // @ts-ignore
                        onMouseEnter={() => setHoveredItem('share')}
                        onMouseLeave={() => setHoveredItem(null)}
                        onClick={onShareClick}
                        className={`text-white ${hoveredItem && hoveredItem !== 'share' ? 'text-opacity-40' : ''} ${hoveredItem === 'share' ? 'font-bold cursor-pointer' : ''} transition duration-300 ease-in-out`}
                    >
                            Share
                    </li>
                </ul>

                {/*@ts-ignore*/}
                <ul className="flex space-x-16 list-none text-xl">
                            {
                                isSignedIn && user?.publicMetadata["store_id"] && <li>
                                    <Link to={"/admin"} // @ts-ignore
                                          onMouseEnter={() => setHoveredItem('admin')}
                                          onMouseLeave={() => setHoveredItem(null)}
                                          className={`text-white ${hoveredItem && hoveredItem !== 'admin' ? 'text-opacity-40' : ''} ${hoveredItem === 'admin' ? 'font-bold cursor-pointer' : ''} transition duration-300 ease-in-out`}
                                          style={{ textDecoration: 'none' }}>
                                        Admin
                                    </Link>
                                </li>
                            }
                            { isSignedIn && <li>
                                    <Link to={"/profile"}// @ts-ignore
                                    onMouseEnter={() => setHoveredItem('profile')}
                                    onMouseLeave={() => setHoveredItem(null)}
                                    className={`text-white ${hoveredItem && hoveredItem !== 'profile' ? 'text-opacity-40' : ''} ${hoveredItem === 'profile' ? 'font-bold cursor-pointer' : ''} transition duration-300 ease-in-out`}
                                    style={{ textDecoration: 'none' }}>
                                        Profile
                                    </Link>
                                </li>
                        }
                    <SignedOut>
                        <li>
                            <Link to='/login'
                                    // @ts-ignore
                                  onMouseEnter={() => setHoveredItem('login')}
                                  onMouseLeave={() => setHoveredItem(null)}
                                  className={`text-white ${hoveredItem && hoveredItem !== 'login' ? 'text-opacity-40' : ''} ${hoveredItem === 'login' ? 'font-bold cursor-pointer' : ''} transition duration-300 ease-in-out`}
                                  style={{ textDecoration: 'none', userSelect: 'none' }}
                            >
                                Login
                            </Link>
                        </li>
                    </SignedOut>
                    <li
                            // @ts-ignore
                              onMouseEnter={() => setHoveredItem('cart')}
                              onMouseLeave={() => setHoveredItem(null)}
                              className={`text-white ${hoveredItem && hoveredItem !== 'cart' ? 'text-opacity-40' : ''} ${hoveredItem === 'cart' ? 'font-bold cursor-pointer' : ''} transition duration-300 ease-in-out`}
                              style={{ textDecoration: 'none', display: 'none' }}
                        >
                            <ShoppingCart cartItems={[]}/>
                    </li>
                </ul>
            </div>
        </div>
            {/*<LoginModal isOpen={isLoginOpen} onClose={() => setIsLoginOpen(false)} />*/}
        </>
    );
}

export default TopNavBar;
