import React from "react"
import {SignUp} from "@clerk/clerk-react";

function CustomSignUp() {
    return (
        <div className="flex justify-center items-center min-h-screen">
            <SignUp appearance={{
                elements: {
                    footer: "hidden",
                    card: "bg-white z-0"
                }
            }}></SignUp>
        </div>);
}

export default CustomSignUp

