import React, {useState} from "react";
import '../styles/Store.css';
import '../styles/Washroom.css';
import {SquareHaloIcon} from "./Icons";

function SquareModal({position, buttonSize, toggleModal, activeModalId, modalData}) {
    let [selectedProductIndex, setSelectedProductIndex] = useState(0);
    let [selectedProductImageIndex, setSelectedProductImageIndex] = useState(0);


    const isModalOpen = (modalData.modalId === activeModalId);

    if(!isModalOpen) {
        return (
            <div className={`absolute ${position}`}>
                {!isModalOpen && <SquareHaloIcon className={`${buttonSize}`} onClick={toggleModal} isOpen={isModalOpen}/>}
            </div>
        )
    }

    if(modalData.videos !== null && modalData.videos !== undefined) {
        return (
            <>
            <div className={'fixed inset-0 bg-black bg-opacity-80'}>

            </div>
            <div className={"flex items-start z-10 text-white h-[75%] overflow-y-auto"}>
                <div className={'relative mb-10 left-[90%]'}>
                    <SquareHaloIcon className={"h-10 w-10"} onClick={toggleModal} isOpen={isModalOpen}/>
                </div>
                <ol type={"I"} className="flex justify-start flex-col gap-10 ml-20">
                    {
                        modalData.videos.map(function(video, index) {
                            const romanIndex = numberToRoman(index + 1);
                            return (
                                <li key={index} className="list-none flex justify-start w-full">
                                        <div className="flex text-4xl mr-4 mt-20 w-20 font-[inknut]">{romanIndex}.</div>
                                        <video className="h-[20%] w-[25%] rounded-2xl" controls>
                                            <source src={"/Final003.mp4"} type="video/mp4"/>
                                        </video>
                                        <div className={"ml-10 border-r-0 border-t-0 border-b-0 border-l-2 border-white border-solid h-[192px]"}></div>
                                        <div className={"ml-10 h-[192px]"}>
                                            <h1 className="text-2xl">{video.title}</h1>
                                            <p className={"whitespace-pre-line"}>{video.description}</p>
                                        </div>

                                </li>
                            )
                        })
                    }
                </ol>
            </div>
            </>
        )
    }

    let modalProducts = modalData.products;
    let currentProduct = modalProducts[selectedProductIndex];

    return (
        <>
            <div className={'fixed inset-0 bg-black bg-opacity-80'}>

            </div>


            <div className={"flex justify-center items-start z-10 text-white"} id={"squareModalMainDiv"}>
                <div className={"flex flex-row justify-center items-start"}>

                    <div className={'flex flex-col hide-scroll overflow-y-auto h-[400px] items-center mt-[70px]'}>
                        {
                            currentProduct.images.map(
                                function(src, index) {
                                    return (
                                        <img onClick={() => setSelectedProductImageIndex(index)} className="bg-gray-300 bg-opacity-30 m-5 rounded-lg border-solid border-2 border-white object-cover hover:cursor-pointer w-[150px] h-[150px]" src={src} />
                                    )
                                }
                            )
                        }
                    </div>


                    <div className="flex flex-col items-center mr-5 ml-5">
                        <h1 className="text-2xl -mt-24">{currentProduct.title}</h1>
                        <p className="text-xl mt-2 -mt-10">${currentProduct.price}</p>
                        {
                            currentProduct.title === "Scrooge McDuck LV Supreme Painting" &&
                            (<img className="w-[300px] h-[300px] object-cover mt-[70px]" src={currentProduct.images[selectedProductImageIndex]} alt="Product" />)
                        }
                        {
                            currentProduct.title !== "Scrooge McDuck LV Supreme Painting" &&
                            (<img className="w-[400px] h-[400px] object-cover mt-[70px]" src={currentProduct.images[selectedProductImageIndex]} alt="Product" />)
                        }

                    </div>

                    <div className="flex flex-col gap-4 items-center">
                        <div className={'relative mb-10 left-[50%]'}>
                            <SquareHaloIcon className={"h-10 w-10"} onClick={toggleModal} isOpen={isModalOpen}/>
                        </div>


                        <p className={'pl-[16px] whitespace-pre-line border-l-2 border-solid border-r-0 border-t-0 border-b-0 mt-[70px]'}>{currentProduct.description}</p>

                        <div className={'pl-4 flex flex-row gap-8 w-full'}>
                            <div className={'hover:cursor-pointer text-center w-[35%] bg-gray-300 bg-opacity-30 rounded-[1rem] border-1 border-solid p-2'}>
                                <span className={'inline-block'}>Buy Now</span>
                            </div>
                            <div className={'hover:cursor-pointer text-center w-[35%] bg-gray-300 bg-opacity-30 rounded-[1rem] border-1 border-solid p-2'}>
                                <span className={'inline-block'}>Add to Cart</span>
                            </div>
                        </div>
                    </div>

                </div>
                { /*modalProducts.length > 1 && (
                <div className="flex flex-row justify-center items-start w-full">
                    <div className={'flex flex-row w-[38rem] overflow-x-auto'}>
                        {

                            modalProducts.map(
                                function(product, index) {
                                    return (
                                        <img onClick={() => setSelectedProductIndex(index)} className="bg-gray-300 bg-opacity-30 m-5 rounded-lg border-solid border-2 border-white h-auto w-[10rem] object-cover hover:cursor-pointer" src={product.images[0]} />
                                    )
                                }
                            )
                        }
                    </div>
                </div>)*/}
            </div>
        </>
    )
}

function numberToRoman(num) {
    const values = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1];
    const numerals = ["M", "CM", "D", "CD", "C", "XC", "L", "XL", "X", "IX", "V", "IV", "I"];
    let roman = '';
    for (let i = 0; i < values.length; i++) {
        while (num >= values[i]) {
            roman += numerals[i];
            num -= values[i];
        }
    }
    return roman;
}

export default SquareModal