import React, {
  useEffect,
  // useRef,
  useState
} from 'react'
import YouTube from "react-youtube";
import {PlayButtonIcon} from "./Icons";
// import view from "./View";


function TheaterRoom({viewData}) {
    const [selectedVideo, setSelectedVideo] = useState("https://www.youtube.com/watch?v=JrkrhaBDsuY");
    const [isVideoPlayerOpen, setIsVideoPlayerOpen] = useState(false);
    const aspectRatio = 12 / 9; // Replace with your screen's aspect ratio
    const [playerSize, setPlayerSize] = useState(null);
    // Calculate initial player size
    useEffect(() => {
      const calculatePlayerSize = () => {
          let screenWidth = window.innerWidth;
          let screenHeight = window.innerHeight;
          let playerWidth = screenWidth * 0.49;
          if (screenWidth / screenHeight < 1.74) {
              if(screenWidth < 1370) {
                  playerWidth = 830;
              }
              if (screenWidth < 1025) {
                  playerWidth = 654;
              }
          }
          let playerHeight = playerWidth / aspectRatio;
          if (playerHeight > screenHeight * 0.8) {
              playerHeight = screenHeight * 0.8;
              playerWidth = playerHeight * aspectRatio;
          }
          setPlayerSize({ width: `${playerWidth}px`, height: `${playerHeight}px` });
      };

        calculatePlayerSize();
        window.addEventListener('resize', calculatePlayerSize);
        return () => {
            window.removeEventListener('resize', calculatePlayerSize);
        }
    }, [aspectRatio]);


    const opts = {
        height: playerSize?.height,
        width: playerSize?.width,
        playerVars: {
            autoplay: 1,
        },
    };

    const toggleVideoPlayer = () => {
        setIsVideoPlayerOpen(true);
    }

    // @ts-ignore
    const handleVideoClick = (videoLink) => {
        setSelectedVideo(videoLink);
    };

    const videoData1 = viewData.ytLinks.slice(0, 4);
    const videoData2 = viewData.ytLinks.slice(4, 8);


    return(
        <>

            <div className={"flex flex-col mr-8 text-[#FFFBFBCC] absolute left-[2%]"}>
                {
                    // @ts-ignore
                    videoData1.map(video => (
                        <div key={video.id} className={"hover:border-white hover:border-solid hover:text-white p-2 mb-10 bg-[#423F3F66] rounded-full hover:cursor-pointer"} onClick={() => handleVideoClick(video.src)}>
                            <span>{video.title}</span>
                        </div>
                    ))
                }
            </div>


            {(!isVideoPlayerOpen) && (<div className={"z-0 absolute top-[47%] right-[47%] hover:cursor-pointer"} onClick={() => toggleVideoPlayer()}> <PlayButtonIcon className={"hover:cursor-pointer fill-gray-600 stroke-gray-600"}/></div> )
            }

            {isVideoPlayerOpen && <Player opts={opts} viewData={viewData}
                                          setSelectedVideo={setSelectedVideo} selectedVideo={selectedVideo}/>}

            <div className={"flex flex-col text-[#FFFBFBCC] absolute right-[4%]"}>
                {
                    // @ts-ignore
                    videoData2.map(video => (
                        <div key={video.id} className={"hover:border-white hover:border-solid hover:text-white p-2 mb-10 bg-[#423F3F66] rounded-full hover:cursor-pointer"} onClick={() => handleVideoClick(video.src)}>
                            <span>{video.title}</span>
                        </div>
                    ))
                }
            </div>
        </>
    );
}

function Player({opts, viewData, setSelectedVideo, selectedVideo}) {
    // @ts-ignore
    const getVideoId = (url) => {
        const videoId = url.split("v=")[1];
        const ampersandPosition = videoId.indexOf('&');
        if (ampersandPosition !== -1) {
            return videoId.substring(0, ampersandPosition);
        }
        return videoId;
    }

    const onEnd = () => {
        //@ts-ignore
        const currentVideoIndex = viewData.ytLinks.findIndex(video => video.src === selectedVideo);
        const nextVideoIndex = (currentVideoIndex + 1) % viewData.ytLinks.length;
        const nextVideoLink = viewData.ytLinks[nextVideoIndex].src;
        setSelectedVideo(nextVideoLink);
    }

    return (
        <YouTube
            videoId={getVideoId(selectedVideo)}
            opts={opts}
            onEnd={onEnd}
            containerClassName="youtube-player-container"
            className={"youtube-player absolute bg-black"}
        />
    );
}

export default TheaterRoom;
