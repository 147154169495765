import
  React
  //, { useState }
from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import View from './View';
// import ProductModal from "./ProductModal";

// @ts-ignore
function Store({ storeData }) {
    console.log(storeData);
    return (
        <div>

            <Routes>
                {
                    storeData.views.map(
                        //@ts-ignore
                        function(view) {
                            return (
                                <Route key={view.id} path={`${view.id}`}
                                       element={<View viewData={view} productData={storeData.productData} views={storeData.views}/>} />
                            )
                        }

                )}
                <Route path="/" element={<Navigate to={`${storeData.views[0].id}`} replace />} />
            </Routes>
        </div>
    );
}

export default Store;
