export const stores = [
    {
        playerName: 'Gary Harris',
        previewImage: '/images/garyharris/GH_1_orange.png',
        id: 'garyharris',
        views: [
            {
                id: 'lounge',
                name: 'Lounge',
                backgroundImage: '/images/garyharris/GH_1_orange.png',
                colorVariations: [
                    '/images/garyharris/GH_1_green.png',
                    '/images/garyharris/GH_1_violet.png'
                ],
                links: [

                ],
            },
            {
                id: 'cinema',
                name: 'Cinema',
                backgroundImage: '/images/garyharris/GH_3_cropped.png',
                links: [

                ],
                ytLinks: [
                    {id: 1, src: "https://www.youtube.com/watch?v=JrkrhaBDsuY", title: "DC Highlights, March 2023"},
                    {id: 2, src: "https://www.youtube.com/watch?v=awh1JbCP400", title: "Game Winner OKC"},
                    {id: 3, src: "https://www.youtube.com/watch?v=myO-QpTLvas", title: "Denver Throwback Highlights"},
                    {id: 4, src: "https://www.youtube.com/watch?v=E8WpyspASpk", title: "Podcast 520 w/ Teague"},
                    {id: 5, src: "https://www.youtube.com/watch?v=Zomc64F6yCg", title: "Career High 36"},
                    {id: 6, src: "https://www.youtube.com/watch?v=pw5yjdwcUwQ", title: "Michigan State Highlight"},
                    {id: 7, src: "https://www.youtube.com/watch?v=1owgHcPrdXg", title: "Career High Tie"},
                    {id: 8, src: "https://www.youtube.com/watch?v=A61a6tFOTVM", title: "Keeping it G"},
                ]
            },
            {
                id: 'office',
                name: 'Office',
                backgroundImage: '/images/garyharris/GH_4.png',
                links: [

                ],
            },
            {
                id: 'gallery',
                name: 'Gallery',
                backgroundImage: '/images/garyharris/GH_7.png',
                links: [

                ],
            },
            {
                id: 'stats-room',
                name: 'Stats Room',
                backgroundImage: '/images/garyharris/GH_8.png',
                links: [

                ],
            },
            {
                id: 'washroom',
                name: 'Washroom',
            },
            {
                id: 'closet',
                name: 'Closet',
            },
            {
                id: 'gym',
                name: 'Gym'
            }
        ],
        productData: {
            categoryList: ["Tops", "Bottoms", "Outwear", "Accessories"],
            categories: [
                {
                    categoryName: "Tops",
                    subcategoryList: ["T-shirts", "Hoodies", "Long Sleeves"],
                    subcategories: [
                        {
                            subCategoryName: "T-shirts",
                            products: [
                                {
                                    name: "Shirt 1",
                                    price: 300.00,
                                    imageSrc: "/images/products/demo/t_shirt-removebg-preview.png",
                                    itemDescription: "Description of item"
                                },
                                {
                                    name: "Shirt 2",
                                    price: 300.00,
                                    imageSrc: "/images/products/demo/t_shirt_-removebg-preview.png",
                                    itemDescription: "Description of item"
                                },
                            ]
                        },
                        {
                            subCategoryName: "Hoodies",
                            products: [
                                {
                                    name: "Hoodie 1",
                                    price: 300.00,
                                    imageSrc: "/images/products/demo/alyxfurblk1_1080x-removebg-preview 1.png",
                                    itemDescription: "Description of item"
                                },
                                {
                                    name: "Hoodie 2",
                                    price: 300.00,
                                    imageSrc: "/images/products/demo/JUSTIN905_1512x-removebg-preview.png",
                                    itemDescription: "Description of item"
                                },
                                {
                                    name: "Hoodie 3",
                                    price: 300.00,
                                    imageSrc: "/images/products/demo/JUSTIN1072_1512x-removebg-preview 1.png",
                                    itemDescription: "Description of item"
                                },
                                {
                                    name: "Hoodie 4",
                                    price: 300.00,
                                    imageSrc: "/images/products/demo/JUSTIN1144_1512x-removebg-preview.png",
                                    itemDescription: "Description of item"
                                },
                            ]
                        },
                        {
                            subCategoryName: "Long Sleeves",
                            products: [
                                {
                                    name: "Long Sleeve 1",
                                    price: 300.00,
                                    imageSrc: "/images/products/demo/wdcrewwht1_1512x-removebg-preview 1.png",
                                    itemDescription: "Description of item"
                                },
                                {
                                    name: "Long Sleeve 2",
                                    price: 300.00,
                                    imageSrc: "/images/products/demo/saint_crewneck-removebg-preview.png",
                                    itemDescription: "Description of item"
                                },
                                {
                                    name: "Long Sleeve 3",
                                    price: 300.00,
                                    imageSrc: "/images/products/demo/Screen_Shot_2023-09-18_at_12.21.png",
                                    itemDescription: "Description of item"
                                },
                            ]
                        }
                    ]
                },
                {
                    categoryName: "Bottoms",
                    subcategoryList: ["Pants", "Sweats"],
                    subcategories: [
                        {
                        subCategoryName: "Pants",
                        products: [
                            {
                                name: "Pant 1",
                                price: 300.00,
                                imageSrc: "/images/products/demo/JUSTIN859_1512x-removebg-preview.png",
                                itemDescription: "Description of item"
                            },
                            {
                                name: "Pant 2",
                                price: 300.00,
                                imageSrc: "/images/products/demo/JUSTIN1138_1512x-removebg-preview.png",
                                itemDescription: "Description of item"
                            },
                            {
                                name: "Pant 3",
                                price: 300.00,
                                imageSrc: "/images/products/demo/kspantmoon1_1512x-removebg-preview.png",
                                itemDescription: "Description of item"
                            },
                            {
                                name: "Pant 4",
                                price: 300.00,
                                imageSrc: "/images/products/demo/saint_sweats-removebg-preview.png",
                                itemDescription: "Description of item"
                            },
                            {
                                name: "Pant 5",
                                price: 300.00,
                                imageSrc: "/images/products/demo/Screen_Shot_2023-09-18_at_12.22.png",
                                itemDescription: "Description of item"
                            },
                        ]
                    }
                ]
                },
                {
                    categoryName: "Outerwear",
                    subcategoryList: ["Jackets", "Hoodies"],
                    subcategories: [
                        {
                            subCategoryName: "Jackets",
                            products: [
                                {
                                    name: "THE ELDER STATESMAN",
                                    price: 1200,
                                    imageSrc: "/images/products/demo/Screen_Shot_2023-09-18_at_12.21.png",
                                    itemDescription: "SSENSE XX Multicolor Patchwork Cashmere Jacket Patchworked knit cashmere jacket.\n" +
                                    "Colorblocking throughout.· Spread collar· Two-way zip closure· Seam pockets· Dropped shoulders·\n" +
                                    "Full cotton broadcloth lining. Part of the SSENSE XX exclusive offering.Supplier color: Multi Body: 100% cashmere.\n" +
                                    "Lining: 100% cotton. Made in United States. 241014M180021"
                                }
                            ],
                        }
                    ]
                },
                {
                    categoryName: "Accessories",
                    subcategoryList: ["Watches", "Sunglasses"],
                }
            ],
        }
    },
    {
        playerName: '?',
        previewImage: '/images/demostorepreviews/store2.png',
        id: '?',
    },
    {
        playerName: '?',
        previewImage: '/images/demostorepreviews/store3.png',
        id: '?',
    },
]