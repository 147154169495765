import { css } from '@emotion/react';
import { useGSAP } from "@gsap/react";
import {
  Environment,
  Html,
  MeshTransmissionMaterial,
  OrbitControls,
  PerspectiveCamera,
  View,
  useGLTF,
  useProgress,
  useTexture
} from "@react-three/drei";
import {
  Canvas,
  extend,
  useFrame,
  useThree
} from "@react-three/fiber";
import gsap from 'gsap';
import ClipLoader from 'react-spinners/ClipLoader';

import { geometry } from 'maath';
import { Suspense, useContext, useEffect, useMemo, useRef, useState } from "react";
import * as THREE from "three";
import { SquareHaloIcon } from "../Icons";
import { RoomContext } from './Contexts';
extend(geometry);
gsap.registerPlugin(useGSAP);

function PopUpComponent() {
  const { scene } = useGLTF(`/models/shirt.glb`);
  const products3Texture = useTexture("/updated/products3.jpg");
  products3Texture.flipY = false;
  products3Texture.encoding = THREE.sRGBEncoding;
  products3Texture.generateMipmaps = false;
  useEffect(() => {
    scene.traverse((node) => {
      if (node.type === 'Mesh') {
        node.material = new THREE.MeshBasicMaterial({
          map: products3Texture,
          toneMapped: false,
        });
      }
    });
  }, []);

  return (
    <>

      <primitive object={scene} />
    </>
  );
}
function PopUpTrigger() {
  const { setSelectedProduct } = useContext(RoomContext);

  return (
    <Html position={[1.86, 1.114564895629883, -3.97]} transform occlude scale={0.1}>
      <div>
        <SquareHaloIcon className={`whiteShirt`} onClick={() => {
          setSelectedProduct('shirt')
        }} />
      </div>
    </Html>
  );
}
function Btns() {
  const { selectedRoom, setSelectedRoom, selectedProduct } = useContext(RoomContext);

  return (
    <>
      {selectedRoom === 'second' && selectedProduct === 'closed' || selectedProduct === 'initial' ? (
        <Html position={[.4, 2.314564895629883, -9.4]} rotation={[0, 0, 0]} transform occlude scale={0.2} >
          <div>
            <SquareHaloIcon className={`firstRoom`} onClick={() => setSelectedRoom('first')} />
          </div>
        </Html>
      ) : null}
      {selectedRoom === 'first' && selectedProduct === 'closed' || selectedProduct === 'initial' ? (
        <Html position={[0.05529100075364113, 2, 0.3]} transform occlude scale={0.2}>
          <div>
            <SquareHaloIcon className={`secondRoom`} onClick={() => setSelectedRoom('second')} />
          </div>
        </Html>
      ) : null}
    </>
  );
}

function Closet(params) {
  const closet = useGLTF(`/models/${params.model}.glb`);

  const productsTexture = useTexture("/updated/products.png");
  productsTexture.flipY = false;
  productsTexture.colorSpace = THREE.SRGBColorSpace;
  productsTexture.generateMipmaps = false
  productsTexture.anisotropy = false


  const settingRoomDiffTexture = useTexture("/updated/settinRoomDiff.png");
  settingRoomDiffTexture.flipY = false;
  settingRoomDiffTexture.colorSpace = THREE.SRGBColorSpace;
  settingRoomDiffTexture.generateMipmaps = false

  const settingRoomTexture = useTexture("/updated/settingRoom.jpg");
  settingRoomTexture.flipY = false;
  settingRoomTexture.colorSpace = THREE.SRGBColorSpace;
  settingRoomTexture.generateMipmaps = false

  const avtarProductsTexture = useTexture("/updated/avtarProducts.jpg");
  avtarProductsTexture.flipY = false;
  avtarProductsTexture.colorSpace = THREE.SRGBColorSpace;
  avtarProductsTexture.generateMipmaps = false

  const kitTexture = useTexture("/updated/kit.jpg");
  kitTexture.flipY = false;
  kitTexture.colorSpace = THREE.SRGBColorSpace;
  kitTexture.generateMipmaps = false


  const lightMap1Texture = useTexture("/updated/lightMap1_UPDATED OPT 2.jpg");
  lightMap1Texture.flipY = false;
  lightMap1Texture.colorSpace = THREE.SRGBColorSpace;
  lightMap1Texture.generateMipmaps = false

  const lightMap2Texture = useTexture("/updated/backRoomLightmap_UPDATED OPT 2.jpg");
  lightMap2Texture.flipY = false;
  lightMap2Texture.colorSpace = THREE.SRGBColorSpace;
  lightMap2Texture.generateMipmaps = false

  const floorDiffTexture = useTexture("/updated/floorDiff.jpg");
  floorDiffTexture.flipY = false;
  floorDiffTexture.colorSpace = THREE.SRGBColorSpace;
  floorDiffTexture.generateMipmaps = false

  const domeTexture = useTexture("/updated/dome_UPDATED OPT 2.jpg");
  domeTexture.flipY = false;
  domeTexture.colorSpace = THREE.SRGBColorSpace;
  domeTexture.generateMipmaps = false
  const products3Texture = useTexture("/updated/products3.jpg");
  products3Texture.flipY = false;
  products3Texture.colorSpace = THREE.SRGBColorSpace;
  products3Texture.generateMipmaps = false
  const products2Texture = useTexture("/updated/products2.jpg");
  products2Texture.flipY = false;
  products2Texture.colorSpace = THREE.SRGBColorSpace;
  products2Texture.generateMipmaps = false

  const products4Texture = useTexture("/updated/products4.png");
  products4Texture.flipY = false;
  products4Texture.colorSpace = THREE.SRGBColorSpace;
  products4Texture.generateMipmaps = false
  useEffect(() => {

    closet.scene.traverse((node) => {
      if (node.type === 'Mesh') {
        if (node.name.includes('bear')) {
          node.material = new THREE.MeshBasicMaterial({
            map: productsTexture,
            toneMapped: false,
            transparent: true,
            alphaTest: 0.5,

          });

        }
        switch (node.name) {
          case "mid_Century_Modern_Chair":
            node.material = new THREE.MeshBasicMaterial({
              map: settingRoomDiffTexture,
              lightMap: settingRoomTexture,
              toneMapped: false,
            });
            break;
          case "Goyard_Bag008":
            node.material = new THREE.MeshBasicMaterial({
              map: products4Texture,
              toneMapped: false,
              transparent: true,
              alphaTest: 0.5,
            });
            break;
          case "mid_Century_Modern_Chair001":
            node.material = new THREE.MeshBasicMaterial({
              lightMap: settingRoomTexture,
              toneMapped: false,
              color: 0xfffbe9,
            });
            break;
          case "mid_Century_Modern_Chair002":
            node.material = new THREE.MeshStandardMaterial({
              map: settingRoomDiffTexture,
              lightMap: settingRoomTexture,
              toneMapped: false,
              roughness: 0.1,
              metalness: 0.5,
              color: 0xfffbe9,
            });
            break;
          case "Hoodie_Hanging007":
            node.material = new THREE.MeshBasicMaterial({
              map: products2Texture,
              toneMapped: false,
            });
            break;
          case "Hoodie_Hanging001":
            node.material = new THREE.MeshBasicMaterial({
              map: products3Texture,
              toneMapped: false,
            });
            break;
          case "tennis_shoes_034001":
            node.material = new THREE.MeshBasicMaterial({
              map: avtarProductsTexture,
              toneMapped: false,
            });
            break;
          case "Cube001":
            node.material = new THREE.MeshBasicMaterial({
              map: kitTexture,
              toneMapped: false,
              reflectivity: 1,
            });
            break;
          case "Shelf004":
            node.material = new THREE.MeshStandardMaterial({
              lightMap: lightMap1Texture,
              toneMapped: false,
              envMapIntensity: 0.1,
              roughness: 0.3,
              color: 0xfffbe9,
            });
            break;
          case "Support_Wall_Right":
            node.material = new THREE.MeshStandardMaterial({
              lightMap: lightMap2Texture,
              toneMapped: false,
              envMapIntensity: 0.1,
              roughness: 0.8,
              color: 0xfffbe9,
            });
            break;
          case "Carpet":
          case "Carpet002":
            node.material = new THREE.MeshStandardMaterial({
              map: floorDiffTexture,
              lightMap: domeTexture,
              toneMapped: false,
              roughness: 1,
              color: 0xfffbe9,
              envMapIntensity: 1.1,
            });
            break;
          case "Carpet001":
            node.material = new THREE.MeshStandardMaterial({
              lightMap: domeTexture,
              toneMapped: false,
              envMapIntensity: 0.1,
              roughness: 0.8,
              color: 0xfffbe9,
            });
            break;
          default:
            break;
        }
        node.material.side = THREE.DoubleSide;
      }
    });
  }, []);


  return (
    <primitive object={closet.scene} />
  );
}

function SecondControls() {
  const { pointer } = useThree();
  const controlsRef = useRef();
  const [initialTarget] = useState(new THREE.Vector3(0, 0, 0));

  useFrame(() => {
    if (controlsRef.current) {
      const { x, y } = pointer;
      const targetX = (x - 0.5) * 0.1;
      const targetY = (y - 0.5) * 0.1;

      const targetPosition = initialTarget
        .clone()
        .add(new THREE.Vector3(targetX, targetY, 0));

      controlsRef.current.target.lerp(targetPosition, 0.1);
    }
  });

  return (
    <>
      <OrbitControls
        target={[0, 0, 0]}
        enableDamping={true}
        minPolarAngle={1}
        maxPolarAngle={1.6}
        maxDistance={9.435977643548352}
      />
    </>
  );
}

function Controls() {
  const { selectedRoom, selectedProduct } = useContext(RoomContext);
  const { pointer, camera } = useThree();
  const { initialCamera, setInitialCamera } = useContext(RoomContext);
  const { initialTarget, setInitialTarget } = useContext(RoomContext);
  const controlsRef = useRef();
  const { animationCompleted, setAnimationCompleted } = useContext(RoomContext);

  let preInitCamera = new THREE.Vector3(0.06636178394072342, 2.2487226924375983, 9.4);
  let preInintTarget = new THREE.Vector3(0, 2, 0);
  if (selectedProduct === 'initial') {
    preInitCamera = (new THREE.Vector3(-0.13206870316479097, 1.2529703228793116, -1.1442171148978835))
    preInintTarget = (new THREE.Vector3(-0.07884848790534048, 1.1830121313999675, -4.225236302578218));

  }
  useGSAP(() => {
    let t1 = gsap.timeline({
      onComplete: () => {
        setAnimationCompleted(true);
      }
    });

    if (controlsRef.current) {
      t1.to(camera.position, {
        x: initialCamera.x,
        y: initialCamera.y,
        z: initialCamera.z,
        duration: 1
      }, 0);

      t1.to(controlsRef.current.target, {
        x: initialTarget.x,
        y: initialTarget.y,
        z: initialTarget.z,
        duration: 1
      }, 0);

    }

  }, [initialCamera, initialTarget, animationCompleted]);



  useEffect(() => {
    if (animationCompleted) {
      preInitCamera = initialCamera
      preInintTarget = initialTarget
    }
  }, [animationCompleted]);
  useEffect(() => {
    if (selectedRoom === 'first') {
      setInitialTarget(new THREE.Vector3(0.029247677116193346, 1.526612271066379, 4.416747209762944));
      setInitialCamera(new THREE.Vector3(0.09642847085903163, 1.7384882979956837, 7.631219185578811));
    }

    if (selectedRoom === 'second') {
      setInitialCamera(new THREE.Vector3(-0.13206870316479097, 1.2529703228793116, -1.1442171148978835))
      setInitialTarget(new THREE.Vector3(-0.07884848790534048, 1.1830121313999675, -4.225236302578218));
    }
    if (selectedRoom === 'second' && selectedProduct === 'shirt') {
      setInitialCamera(new THREE.Vector3(-0.13206870316479097, 1.2529703228793116, -1.1442171148978835))
      setInitialTarget(new THREE.Vector3(-0.07884848790534048, 1.1830121313999675, -4.225236302578218));
    }

  }, [selectedRoom, selectedProduct]);
  const rotationConstraints = useMemo(() => {

    if (selectedRoom === 'first') {
      return {
        minAzimuthAngle: -Math.PI,
        maxAzimuthAngle: Math.PI
      };
    } else if (selectedRoom === 'second') {
      return {
        minAzimuthAngle: -Math.PI / 8,
        maxAzimuthAngle: Math.PI / 8
      };
    }
    return {
      minAzimuthAngle: 0,
      maxAzimuthAngle: 0
    };
  }, [selectedRoom]);

  useFrame(() => {
    if (controlsRef.current && animationCompleted) {
      const { x, y } = pointer;
      const targetX = (x - 0.5) * 0.1;
      const targetY = (y - 0.5) * 0.1;
      const targetPosition = initialTarget
        .clone()
        .add(new THREE.Vector3(targetX, targetY, 0));
      controlsRef.current.target.lerp(targetPosition, 0.1);
    }
  });

  return (
    <>
      <PerspectiveCamera makeDefault position={[preInitCamera.x, preInitCamera.y, preInitCamera.z]} fov={40} />
      <OrbitControls
        ref={controlsRef}
        target={[preInintTarget.x, preInintTarget.y, preInintTarget.z]}
        enableDamping={true}
        enablePan={false}
        enableZoom={false}
        {...rotationConstraints}
        minPolarAngle={1}
        maxPolarAngle={1.6}
        maxDistance={9.435977643548352}
      />
    </>
  );
}

const BlurPlane = () => {
  const planeRef = useRef();
  const { camera } = useThree()
  const { x, y, z } = camera.position;

  return (
    <mesh ref={planeRef} position={[x, y, -3]}>
      <planeGeometry args={[3, 3]} />
      <MeshTransmissionMaterial
        temporalDistortion={0}
        transmission={1}
        roughness={1}
        thickness={.02}
        ior={2}
      />
    </mesh>
  );
};

const Loader = () => {
  const { progress } = useProgress();
  const [pro, setPro] = useState(0);

  const override = css`
  display: block;
  margin: 0 auto;
  border-color: white;
`;


  useEffect(() => {
    if (progress > 0) {
      setPro(progress);
    }
  }, [progress]);

  return (
    <Html center style={{ width: '100vw', height: '100vh', position: 'absolute', backgroundImage: 'url("/images/garyharris/Closet/loader 1.jpg")', backgroundSize: 'cover', backgroundPosition: 'center' , }}>
      <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', color: 'white' }}>
        <ClipLoader css={override} size={50} loading={true} />

      </div>
    </Html>
  );
};



export default function App(params) {
  const { selectedProduct, setSelectedProduct } = useContext(RoomContext);
  const { showView } = useContext(RoomContext);
  const ref = useRef(null);
  return (
    <>

      <div ref={ref} className="container">
        {(selectedProduct === 'closed' || selectedProduct === 'initial') && (
          <View index={1} className="view1">
            <PopUpTrigger />

            <Environment files={"/env.hdr"}></Environment>
            <Closet model={params.model}></Closet>
            <Btns></Btns>
            <Controls />
          </View>
        )}
        {(selectedProduct === 'shirt') && (
          <>
            <View index={1} className="view1">
              <PerspectiveCamera makeDefault position={[-0.13206870316479097, 1.2529703228793116, -1.1442171148978835]} fov={40} />
              <Environment files={"/env.hdr"}></Environment>
              <Closet model={params.model}></Closet>
              <BlurPlane></BlurPlane>
            </View>
            <View index={2} className="view2">
              <PerspectiveCamera makeDefault position={[0, 0, .7]} fov={75} />
              <PopUpComponent></PopUpComponent>
              <SecondControls></SecondControls>
            </View>
          </>
        )}

      </div>
      {(selectedProduct === 'shirt') && (

        <div className="closeBtn">
          <SquareHaloIcon className={`whiteShirt`} onClick={() => setSelectedProduct('initial')} /> <span>Close</span>
        </div>
      )}
      <Canvas eventSource={ref} className="canvas" >
        <Suspense fallback={<Loader></Loader>
        } >

          <View.Port />
        </Suspense>

      </Canvas>
    </>

  );
}
