import React, {useEffect, useRef} from 'react'

function PhotoRoom() {
    const scrollableDivRef = useRef(null);
    const initialScrollPercentage = 0.026;

    useEffect(() => {
        const div = scrollableDivRef.current;

        const handleScroll = () => {
            // @ts-ignore
            if (div.scrollTop < div.scrollHeight * initialScrollPercentage) {
                // @ts-ignore
                div.scrollTop = div.scrollHeight * initialScrollPercentage;
            }
        };

        if (div) {
            // @ts-ignore
            div.scrollTop = div.scrollHeight * initialScrollPercentage;
            // @ts-ignore
            div.addEventListener('scroll', handleScroll);
        }

        // Cleanup the listener when the component unmounts
        return () => {
            if (div) {
                // @ts-ignore
                div.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    return (
        <div ref={scrollableDivRef} className={"hide-scroll overflow-y-scroll flex flex-col w-[40%] h-[100vh]"}>
            <img alt="" src={"/images/garyharris/gh_reel.png"} className={""} />
        </div>
    );
}

export default PhotoRoom;
